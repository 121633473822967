<template>
  <fw-layout wide>
    <template #main-content>
      <fw-panel :title="$t('personalDetails')" featured> </fw-panel>

      <fw-panel :title="$t('institutionalDetails')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <ContentLoader v-if="loading" />
        <div v-if="!loading">
          <fw-label>{{ $t('title') }}</fw-label>
          <div>{{ personal.title || $t('notDefined') }}</div>
        </div>

        <div v-if="!loading">
          <fw-label>{{ $t('employeeNumber') }}</fw-label>
          <div>{{ user.number || $t('notDefined') }}</div>
        </div>

        <div v-if="!loading">
          <fw-label>{{ $t('employeeCard') }}</fw-label>
          <div :class="{ 'text-primary': !personal.disabled }">
            {{ personal.disabled ? $t('notActive') : $t('active') }}
          </div>
        </div>
      </fw-panel>

      <fw-panel :title="$t('personalDetails')" boxed="lg" class="my-5" custom-class="bg-white">
        <ContentLoader v-if="loading" />
        <div v-else class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('fullName') }}</fw-label>
            <div>{{ personal.name || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('gender') }}</fw-label>
            <div>{{ personal.gender ? $t(`genderName.${personal.gender}`) : $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('birthdate') }}</fw-label>
            <div>{{ personal.born_at || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('dependentsNumber') }}</fw-label>
            <div>{{ personal.dependents || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('nationality') }}</fw-label>
            <div>{{ personal.nationality || $t('notDefined') }}</div>
          </div>
        </div>
      </fw-panel>

      <fw-panel
        :title="$t('personalContacts')"
        boxed="lg"
        class="my-5"
        custom-class="bg-white"
        :loading="savingData"
        after-loading-checked
        :loading-error="savingDataError"
      >
        <template v-if="!loading" #toolbar>
          <div class="flex gap-1 items-center">
            <div v-if="editMode && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
            <div class="flex gap-5">
              <fw-button
                :type="!editMode ? 'link' : 'link-light'"
                :disabled="savingData"
                @click.native="toggleEditMode()"
              >
                {{ editMode ? $t('close') : $t('edit') }}
              </fw-button>
              <fw-button v-if="editMode" type="primary" :loading="savingData" @click.native="saveData()">{{
                $t('save')
              }}</fw-button>
            </div>
          </div>
        </template>

        <ContentLoader v-if="loading" />
        <div v-if="!loading" class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('address') }}</fw-label>
            <div v-if="!editMode">{{ contacts.address || $t('notDefined') }}</div>
            <TextInput
              v-else
              v-model="contacts.address"
              :minlength="2"
              :maxlength="60"
              :help="$t('charsLimit', { limit: '60' })"
              :placeholder="$t('address')"
              :class="{
                error: $v.contacts.address.$error,
              }"
            ></TextInput>
            <fw-tip v-if="$v.contacts.address.$error" error>
              <span v-if="!$v.contacts.address.required">{{ $t('errors.requiredF', { field: $t('address') }) }}</span>
              <span v-else>{{ ($t('errors.invalidF'), { field: $t('address') }) }}</span>
            </fw-tip>

            <div v-if="!editMode">
              <span v-if="contacts.address_complement">{{ contacts.address_complement || $t('notDefined') }}</span>
            </div>
            <TextInput
              v-else
              v-model="contacts.address_complement"
              class="mt-2"
              :minlength="2"
              :maxlength="40"
              :help="$t('charsLimit', { limit: '40' })"
              :placeholder="$t('addressComplement')"
              :class="{
                error: $v.contacts.address_complement.$error,
              }"
            ></TextInput>
            <fw-tip v-if="$v.contacts.address_complement.$error" error>
              {{ $t('errors.invalidF', { field: $t('addressComplement') }) }}
            </fw-tip>
          </div>

          <div class="flex gap-4">
            <div>
              <fw-label custom-class="inline-flex gap-2"
                >{{ $t('postalCode') }}<fw-icon-checkbox-circle v-if="zipcodeValidatedSAP" class="w-5 h-5 text-primary"
              /></fw-label>
              <div v-if="!editMode">{{ contacts.zipcode || $t('notDefined') }}</div>
              <TextInput
                v-else
                v-model="contacts.zipcode"
                placeholder="XXXX-XXX"
                :class="{
                  error: $v.contacts.zipcode.$error,
                }"
              ></TextInput>
              <fw-tip v-if="$v.contacts.zipcode.$error" error>
                <span v-if="!$v.contacts.zipcode.required">{{
                  $t('errors.required', { field: $t('postalCode').toLowerCase() })
                }}</span>
                <span v-else>{{ $t('errors.invalid', { field: $t('postalCode').toLowerCase() }) }}</span>
              </fw-tip>
            </div>

            <div>
              <fw-label>{{ $t('postalCodeLocal') }}</fw-label>
              <div>{{ contacts.zipcode_local || $t('notDefined') }}</div>
            </div>
          </div>
          <div>
            <fw-button v-if="editMode" type="link" @click.native="validateZipcode">Validar código postal</fw-button>
          </div>

          <div>
            <fw-label>{{ $t('homePhone') }}</fw-label>
            <div v-if="!editMode">{{ contacts.home_phone || $t('notDefined') }}</div>
            <NumberInput
              v-else
              v-model="contacts.home_phone"
              :disabled="!editMode"
              :class="{
                error: $v.contacts.home_phone.$error,
              }"
            >
            </NumberInput>

            <fw-tip v-if="$v.contacts.home_phone.$error" error>
              <span v-if="!$v.contacts.home_phone.required">{{
                $t('errors.required', { field: $t('homePhone').toLowerCase() })
              }}</span>
              <span v-else>{{ $t('errors.invalid', { field: $t('homePhone').toLowerCase() }) }}</span>
            </fw-tip>
          </div>

          <div class="flex gap-4">
            <div>
              <fw-label>{{ $t('phoneCountry') }}</fw-label>
              <div v-if="!editMode">{{ contacts.country_code || $t('notDefined') }}</div>
              <PhoneCountriesSelect
                v-else
                :placeholder="$t('phoneCountry')"
                :input="contacts.country"
                @update="phoneCountryChanged"
              />
            </div>
            <div>
              <fw-label>{{ $t('phone') }}</fw-label>
              <div v-if="!editMode">{{ contacts.phone || $t('notDefined') }}</div>
              <NumberInput
                v-else
                v-model="contacts.phone"
                :placeholder="$t('phone')"
                :class="{
                  error: $v.contacts.phone.$error,
                }"
              ></NumberInput>
              <fw-tip v-if="$v.contacts.phone.$error" error>
                <span v-if="!$v.contacts.phone.required">{{
                  $t('errors.required', { field: $t('phone').toLowerCase() })
                }}</span>
                <span v-else>{{ $t('errors.invalid', { field: $t('phone').toLowerCase() }) }}</span>
              </fw-tip>
            </div>
          </div>

          <div>
            <fw-label>{{ $t('personalAndRecoverEmail') }}</fw-label>
            <div v-if="!editMode">{{ contacts.email || $t('notDefined') }}</div>
            <TextInput
              v-else
              v-model="contacts.email"
              :minlength="2"
              :maxlength="50"
              :class="{
                error: $v.contacts.email.$error,
              }"
            >
            </TextInput>

            <fw-tip v-if="$v.contacts.email.$error" error>
              <span v-if="!$v.contacts.phone.required">{{ $t('errors.required', { field: 'email' }) }}</span>
              <span v-else>{{ $t('errors.invalid', { field: 'email' }) }}</span>
            </fw-tip>
          </div>
        </div>
      </fw-panel>

      <fw-panel :title="$t('institutionalContacts')" boxed="lg" class="my-5" custom-class="bg-white">
        <ContentLoader v-if="loading" />
        <div v-else class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('homePhone') }}</fw-label>
            <div>{{ contacts.home_phone || $t('notDefined') }}</div>
          </div>
          <div>
            <fw-label>{{ $t('phone') }}</fw-label>
            <div>{{ contacts.cell_phone || $t('notDefined') }}</div>
          </div>

          <fw-panel-info class="mt-4" clean>{{ $t('comunicationPlatforms') }}</fw-panel-info>

          <div v-if="user.meeting">
            <fw-label>{{ $t('idUcMeetings') }}</fw-label>
            <div class="flex items-center gap-3">
              {{ user.meeting?.key_alias }}
              <fw-icon-virtual-meeting
                class="h-6 w-6 text-primary"
                :class="{ 'animate-pulse': user.meeting.running }"
              ></fw-icon-virtual-meeting>
              <fw-icon-chats class="h-6 w-6 text-primary"></fw-icon-chats>
            </div>
          </div>

          <div>
            <fw-label>ID skype</fw-label>
            <div class="flex items-center gap-3">
              {{ $t('notDefined') }}
            </div>
          </div>
          <div>
            <fw-label>ID Mircrosoft Teams</fw-label>
            <div class="flex items-center gap-3">
              {{ $t('notDefined') }}
            </div>
          </div>
        </div>
      </fw-panel>
      <fw-panel :title="$t('bankDetails')" boxed="lg" class="my-5" custom-class="bg-white">
        <ContentLoader v-if="loading" />
        <div v-else class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('bank') }}</fw-label>
            <div>{{ bank.name || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>IBAN</fw-label>
            <div>{{ bank.iban || $t('notDefined') }}</div>
          </div>
        </div>
      </fw-panel>
      <fw-panel :title="$t('emailService')" boxed="lg" class="my-5" custom-class="bg-white font-semibold">
        <fw-panel-info centered clean
          >{{ $t('emailServiceInfo') }}
          <a href="https://apps.uc.pt/account" class="text-primary">https://apps.uc.pt/account</a>.
        </fw-panel-info>
      </fw-panel>

      <fw-panel-info debug label="Worker data (raw)">
        <json-viewer :value="{ personal, contacts, bank, institutional }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import PhoneCountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/PhoneCountriesSelect'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { postalCode } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  components: {
    TextInput,
    ContentLoader,
    PhoneCountriesSelect,
    NumberInput,
  },

  data() {
    return {
      editMode: false,
      savingData: false,
      savingDataError: false,
      loading: true,
      personal: {},
      contacts: {},
      bank: {},
      institutional: {},
      zipcodeValidatedSAP: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },
  },

  mounted() {
    this.getWorkerDetails()
  },

  validations: {
    contacts: {
      address: { required, max: maxLength(60), min: minLength(2) },
      address_complement: { max: maxLength(40) },
      zipcode: { required, postalCode },
      country: { required },
      home_phone: { max: maxLength(14) },
      phone: { required, max: maxLength(14) },
      email: { required, email, min: minLength(2), max: maxLength(241) },
    },
  },

  methods: {
    async getWorkerDetails() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserInfo()
        console.log('getUserInfo :>> ', response)
        this.personal = response.personal
        this.contacts = response.contacts
        if (!this.contacts.country) {
          this.contacts.country = {
            key: 'PT',
            code: '+351',
            title: 'Portugal',
          }
        }
        this.bank = response.bank
        this.institutional = response.institutional
      })

      this.loading = false
    },

    async updateDetails() {
      this.savingData = true
      this.savingDataError = false

      const tmpContacts = JSON.parse(JSON.stringify(this.contacts))
      tmpContacts.country = this.contacts.country ? this.contacts.country.key : null
      tmpContacts.phone = this.contacts.phone ? this.contacts.phone : null

      try {
        const response = await this.api.updateUserInfo(tmpContacts, { sync_to_sap: FwEnvConfig == 'production' })
        console.log('updateUserInfo :>> ', response)

        this.$buefy.snackbar.open({
          message: this.$t('personalContactsSaved'),
          type: 'is-success',
          position: 'is-top-right',
          duration: 2000,
          queue: true,
        })
      } catch (e) {
        this.savingDataError = true
        const errorKey = utils.errors(e).getKey()
        console.error(e)

        if (errorKey && errorKey === 'SAPError') {
          this.$buefy.toast.open({
            message: this.$t('errors.sap'),
            type: 'is-danger',
            duration: 4000,
          })
        } else {
          this.$buefy.toast.open({
            message: this.$t('errors.saving'),
            type: 'is-danger',
            duration: 4000,
          })
        }
      }

      this.savingData = false
    },

    async validateZipcode() {
      this.savingData = true
      this.savingDataError = false

      try {
        const response = await this.api.validateSAPzipcodes(this.contacts.zipcode)
        console.log('validateZipcode :>> ', response)
        this.zipcodeValidatedSAP = true
        this.contacts.zipcode = response.code
        this.contacts.zipcode_local = response.local

        this.$buefy.snackbar.open({
          message: 'Código postal válido!',
          type: 'is-success',
          position: 'is-top-right',
          duration: 2000,
          queue: true,
        })
      } catch (e) {
        this.savingDataError = true
        console.error(e)
        this.$buefy.toast.open({
          message: 'Código postal inválido',
          type: 'is-danger',
          duration: 4000,
          queue: true,
        })
      }

      this.savingData = false
    },

    phoneCountryChanged(country) {
      console.log('phoneCountryChanged', country)
      this.$set(this.contacts, 'country', country)
    },

    saveData() {
      console.log('saveData invalid: ', this.$v.$invalid)
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.updateDetails()
      }
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "personalDetails": "Dados pessoais",
    "personalContacts": "Contactos pessoais",
    "institutionalDetails": "Dados institucionais",
    "institutionalContacts": "Contactos institucionais",
    "bankDetails": "Dados bancários",
    "emailService": "Serviço de email",
    "thereAreErrors": "Existem erros no formulário",
    "title": "Título",
    "employeeNumber": "Número de funcionário",
    "employeeCard": "Cartão de funcionário",
    "active": "Ativo",
    "notActive": "Não ativo",
    "fullName": "Nome completo",
    "gender": "Género",
    "address": "Morada",
    "addressComplement": "Morada 2",
    "postalCode": "Código postal",
    "postalCodeLocal": "Localidade",
    "genderName": {
      "M": "Masculino",
      "F": "Femenino"
    },
    "birthdate": "Data de nascimento",
    "civilStatus": "Estado Civil",
    "dependentsNumber": "Nº de dependents",
    "nationality": "Nacionalidade",
    "homePhone": "Telefone",
    "phoneCountry": "Indicativo do país",
    "phone": "Telemóvel",
    "personalAndRecoverEmail": "Email pessoal e de recuperação de password",
    "extension": "Extensão",
    "comunicationPlatforms": "Plataformas de comunicação",
    "idUcMeetings": "ID UC Meetings (sala virtual, canais de conversação)",
    "bank": "Banco",
    "emailServiceInfo": "As configurações do serviço de email estão disponíveis em",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "charsLimit": "Limite de {limit} caracteres.",
    "notDefined": "Não definido.",
    "one": "um",
    "personalContactsSaved": "Contactos pessoais guardados com successo",
    "errors": {
      "loading":"Ocorreu um erro ao obter os dados pessoais",
      "saving":"Ocorreu um erro ao guardar os contactos pessoais",
      "sap":"Ocorreu um erro ao guardar os contactos pessoais em SAP.",
      "required": "Insira o {field}",
      "requiredF": "Insira a {field}",
      "requiredAtLeast": "Insira pelo menos {number} {field}.",
      "invalid": "Insira um {field} válido",
      "invalidF": "Insira uma {field} válida"
    }
  },
  "en": {
    "personalDetails": "Personal details",
    "institutionalDetails": "Institutional details",
    "personalContacts": "Personal contacts",
    "institutionalContacts": "Institutional contacts",
    "bankDetails": "Bank details",
    "emailService": "Email service",
    "emailServiceInfo": "The email service settings are available at",
    "title": "Title",
    "employeeNumber": "Employee number",
    "employeeCard": "Employee Card",
    "active": "Active",
    "notActive": "Not active",
    "fullName": "Full name",
    "gender": "Gender",
    "address": "Address",
    "addressComplement": "Address 2",
    "postalCode": "Zip code",
    "postalCodeLocal": "Location",
    "genderName": {
      "M": "Male",
      "F": "Female"
    },
    "birthdate": "Date of birth",
    "civilStatus": "Civil status",
    "dependentsNumber": "No. of dependents",
    "nationality": "Nationality",
    "homePhone": "Home phone",
    "phoneCountry": "Country code",
    "phone": "Phone",
    "personalAndRecoverEmail": "Personal email and password recovery",
    "extension": "Extension",
    "comunicationPlatforms": "Communication platforms",
    "idUcMeetings": "ID UC Meetings (virtual room, chat channels)",
    "bank": "Bank",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "charsLimit": "Limit of {limit} characters.",
    "yes": "Yes",
    "no": "No",
    "notDefined": "Não definido.",
    "one": "one",
    "personalContactsSaved": "Personal contacts successfully saved",
    "errors": {
      "loading":"An error occurred while obtaining the personal data",
      "saving":"An error occurred when saving personal contacts",
      "sap":"An error occurred when saving personal contacts to SAP.",
      "required": "Enter the {field}",
      "requiredF": "Enter the {field}",
      "requiredAtLeast": "Enter at least {number} {field}.",
      "invalid": "Enter a valid {field}",
      "invalidF": "Enter a valid {field}"
    }
  }
}
</i18n>
